import React from "react";
import Lottie from "react-lottie";

const Logo = () => {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: {
          v: "5.5.1",
          fr: 29.9700012207031,
          ip: 0,
          op: 55.0000022401959,
          w: 300,
          h: 300,
          nm: "Master-Comp",
          ddd: 0,
          assets: [
            {
              id: "comp_0",
              layers: [
                {
                  ddd: 0,
                  ind: 1,
                  ty: 4,
                  nm: "Arrow",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: {
                      a: 1,
                      k: [
                        {
                          i: { x: 0.3, y: 1 },
                          o: { x: 0.333, y: 0 },
                          t: 0,
                          s: [173.34, 126.594, 0],
                          to: [1.667, -1.667, 0],
                          ti: [-1.667, 1.667, 0],
                        },
                        {
                          i: { x: 0.667, y: 0.667 },
                          o: { x: 0.333, y: 0.333 },
                          t: 10,
                          s: [183.34, 116.594, 0],
                          to: [0, 0, 0],
                          ti: [0, 0, 0],
                        },
                        {
                          i: { x: 0.667, y: 1 },
                          o: { x: 0.5, y: 0 },
                          t: 15,
                          s: [183.34, 116.594, 0],
                          to: [-1.667, 1.667, 0],
                          ti: [1.667, -1.667, 0],
                        },
                        { t: 35.0000014255792, s: [173.34, 126.594, 0] },
                      ],
                      ix: 2,
                    },
                    a: { a: 0, k: [27.453, 26.844, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [3.068, 0],
                                [0, 0],
                                [0, -2.3],
                                [-2.299, 0],
                                [0, 0],
                                [0, 0],
                                [-2.169, -2.17],
                                [0, 0],
                                [-2.17, 2.168],
                                [0, 0],
                                [0, 0],
                                [-2.297, 0],
                                [0, 2.297],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [-2.299, 0],
                                [0, 2.299],
                                [0, 0],
                                [0, 0],
                                [-2.169, 2.169],
                                [0, 0],
                                [2.169, 2.168],
                                [0, 0],
                                [0, 0],
                                [0, 2.297],
                                [2.297, 0],
                                [0, 0],
                                [0, -3.069],
                              ],
                              v: [
                                [21.376, -26.865],
                                [-13.275, -26.865],
                                [-17.439, -22.701],
                                [-13.275, -18.536],
                                [6.705, -18.536],
                                [-24.762, 12.93],
                                [-24.762, 20.788],
                                [-20.854, 24.697],
                                [-12.997, 24.697],
                                [18.611, -6.912],
                                [18.611, 13.39],
                                [22.771, 17.549],
                                [26.932, 13.39],
                                [26.932, -21.308],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [
                              0.08627450980392157,
                              0.11764705882352941,
                              0.1803921568627451,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [27.182, 27.115], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 150.000006109625,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "Base",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [150, 150, 0], ix: 2 },
                    a: { a: 0, k: [50.166, 50.215, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          ind: 0,
                          ty: "sh",
                          ix: 1,
                          ks: {
                            a: 0,
                            k: {
                              i: [
                                [2.298, 0],
                                [0, -2.297],
                                [0, 0],
                                [1.535, 0],
                                [0, 0],
                                [0, 1.534],
                                [0, 0],
                                [-1.534, 0],
                                [0, 0],
                                [0, 2.299],
                                [2.299, 0],
                                [0, 0],
                                [0, -3.068],
                                [0, 0],
                                [-3.069, 0],
                                [0, 0],
                                [0, 3.068],
                                [0, 0],
                              ],
                              o: [
                                [-2.297, 0],
                                [0, 0],
                                [0, 1.534],
                                [0, 0],
                                [-1.534, 0],
                                [0, 0],
                                [0, -1.535],
                                [0, 0],
                                [2.299, 0],
                                [0, -2.299],
                                [0, 0],
                                [-3.069, 0],
                                [0, 0],
                                [0, 3.068],
                                [0, 0],
                                [3.069, 0],
                                [0, 0],
                                [0, -2.297],
                              ],
                              v: [
                                [45.757, 5.578],
                                [41.597, 9.738],
                                [41.597, 38.859],
                                [38.819, 41.637],
                                [-38.819, 41.637],
                                [-41.597, 38.859],
                                [-41.597, -38.859],
                                [-38.819, -41.637],
                                [-9.71, -41.637],
                                [-5.546, -45.801],
                                [-9.71, -49.965],
                                [-44.36, -49.965],
                                [-49.916, -44.41],
                                [-49.916, 44.41],
                                [-44.36, 49.965],
                                [44.36, 49.965],
                                [49.916, 44.41],
                                [49.916, 9.738],
                              ],
                              c: true,
                            },
                            ix: 2,
                          },
                          nm: "Path 1",
                          mn: "ADBE Vector Shape - Group",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [
                              0.34509803921568627,
                              0.3137254901960784,
                              0.9254901960784314,
                              1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [50.166, 50.215], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Group 1",
                      np: 2,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 150.000006109625,
                  st: 0,
                  bm: 0,
                },
              ],
            },
          ],
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 0,
              nm: "Share",
              refId: "comp_0",
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [150, 150, 0], ix: 2 },
                a: { a: 0, k: [150, 150, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
              },
              ao: 0,
              w: 300,
              h: 300,
              ip: 0,
              op: 150.000006109625,
              st: 0,
              bm: 0,
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              nm: "Background",
              sr: 1,
              ks: {
                o: { a: 0, k: 0, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [150, 150, 0], ix: 2 },
                a: { a: 0, k: [7, -1, 0], ix: 1 },
                s: { a: 0, k: [156.25, 156.25, 100], ix: 6 },
              },
              ao: 0,
              shapes: [
                {
                  ty: "gr",
                  it: [
                    {
                      ty: "rc",
                      d: 1,
                      s: { a: 0, k: [192, 192], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      nm: "Rectangle Path 1",
                      mn: "ADBE Vector Shape - Rect",
                      hd: false,
                    },
                    {
                      ty: "fl",
                      c: { a: 0, k: [0.97, 0.6, 0.6, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: "Fill 1",
                      mn: "ADBE Vector Graphic - Fill",
                      hd: false,
                    },
                    {
                      ty: "tr",
                      p: { a: 0, k: [7, -1], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: "Transform",
                    },
                  ],
                  nm: "Rectangle 1",
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: "ADBE Vector Group",
                  hd: false,
                },
              ],
              ip: 0,
              op: 150.000006109625,
              st: 0,
              bm: 0,
            },
          ],
          markers: [],
        },
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
      height={40}
      width={40}
    />
  );
};

export default Logo;
